import * as React from "react";
import ReactPlayer, { VimeoConfig, VimeoPlayerProps } from 'react-player/vimeo'
import { useInView } from "react-intersection-observer";

export const VimeoComponent: React.FunctionComponent<{ height?: number, slice?: { primary: any }, vimeoId?: string }> = ({ height, vimeoId, slice }) => {
  const player = React.useRef<ReactPlayer>(null);
  const { ref, inView, entry } = useInView({});
  const [playing, setPlaying] = React.useState(false);


  React.useEffect(() => {
    if (!player.current){
      return;
    }
    if (!playing && inView){
      setPlaying(true);
    }
    if (playing && !inView){
      setPlaying(false);
    }

  }, [inView])
  

  const options : VimeoPlayerProps = {
    url: `https://player.vimeo.com/video/${vimeoId || slice?.primary.vimeo_video_id}`,
    muted: true,
    playing,
    loop: true,
    width: '100%',
    height: '100%',
    playsinline: true,
    vimeo: {
      autoplay: true,
      muted: true
    }
  };
  
  return (
    <>
  <div key={vimeoId || slice?.primary.vimeo_video_id} ref={ref} className="slice vimeo-slice" style={{ height}}>
    <ReactPlayer ref={player} {...options} />
  </div>
  
  {slice?.primary?.caption && <p>{slice.primary.caption}</p>}
  </>
  )
}
