import * as React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import mapStyle from './mapStyle.json'
import './mapControl.scss';

const center = {
  lat: 51.5172747,
  lng: -0.1585801
};


export const MapControl: React.FC = () => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBzl-pA6kHP90jV6eK1tagpmk-jAWErKF0",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <div className="map-wrapper">
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapStyle,
      }}
    >
        <Marker
      onLoad={onLoad}
      position={center}
    />
      <></>
    </GoogleMap>
    </div>
) : <></>
};

