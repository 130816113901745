import * as React from "react";
import { Autoplay, Navigation, Pagination, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage }  from "gatsby-plugin-image";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const CarouselComponent: React.FunctionComponent<{ slice: { primary: any, items: any[] } }> = ({ slice }) => {  

  const options : SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    autoplay: true,
    pagination: { clickable: true },
    navigation: true,
    modules: [Autoplay, Navigation, Pagination]
  };

  console.log(slice.primary);
  
  return (
    <>
    <Swiper className="slice carousel-slice" {...options}>
      {slice.items.map(item => 
      <SwiperSlide key={item.image.alt}>
        <GatsbyImage image={getImage(item.image.gatsbyImageData)!} alt={item.image.alt}/>
      </SwiperSlide>
      )}
    </Swiper>
    {slice.primary?.caption && <p>{slice.primary.caption}</p>}
  </>
  )
}
