import { graphql } from "gatsby";
import * as React from "react";
import { orderBy } from "lodash";
import HomeBlock from "../components/homeBlock";
import LogoSoup, { ILogo } from "../components/logoSoup";
import PageHeader from "../components/pageHeader";
import Placeholder from "../components/placeholder";
import Portfolio, { IWork } from "../components/portfolio";
import { IndexPageQuery } from "../generated/graphql";
import "./index.scss";
import { VimeoComponent } from "../components/sliceComponents/vimeoComponent";

import HomeImage1 from "../assets/images/home-image-1.jpg";
import HomeImage2 from "../assets/images/home-image-2.jpg";
import HomeImage3 from "../assets/images/home-image-3.jpg";

interface IProps {
  data: IndexPageQuery;
}

interface IDocument {
  id?: string;
  data?: IDocumentData;
}

interface IDocumentData {
  title?: string;
  subtitle?: string;
  order?: number;
  short_description?: string;
  card_image?: { gatsbyImageData?: any };
}

const Index: React.FunctionComponent<IProps> = ({ data }) => {
  const newWorks = data.homePage?.data.recent_work?.map((w) => ({
    uid: w.case_study?.uid,
    data: (w.case_study?.document as IDocument).data,
  }));

  const works: IWork[] = newWorks
    ? newWorks.map((n) => ({
        title: n.data?.title || "Case Study",
        subTitle: n.data?.subtitle,
        description: n.data?.short_description,
        imageData: n.data?.card_image?.gatsbyImageData,
        path: `/case-studies/${n.uid}`,
        order: n.data?.order,
      }))
    : [];

  const homePageData = data.homePage?.data;
  return (
    <>
      <div className="fixed-width">
        <PageHeader
          heading={homePageData?.header || ""}
          action={{ text: "View our work", to: "/work" }}
          headingElement="h1"
        >
          <p className="intro">{homePageData?.subheader}</p>
        </PageHeader>

        <VimeoComponent vimeoId={homePageData?.vimeo_id} />
        <div className="home-blocks">
          {homePageData?.offerings?.map((offering) => (
            <HomeBlock
              key={offering.title}
              heading={offering.title || ""}
              action={{
                text: offering.link_text || "",
                to: offering.link_to || "/",
              }}
            >
              {offering.body}
            </HomeBlock>
          ))}
          {/* <HomeBlock
            heading="Brand and service design"
            action={{ text: "Brand and service", to: "/brand-and-service" }}
          >
            We’ll design your brand and services in parallel to create better
            work, united teams and a more efficient process. Make the right
            promise (brand) and deliver it in the right way (service). Start
            here.
          </HomeBlock>

          <HomeBlock
            heading="Proposition prototyping"
            action={{
              text: "Proposition prototyping",
              to: "/proposition-prototyping",
            }}
          >
            It’s difficult to explore new avenues when you and your team are
            focussed on business as usual. De-risk your next step by testing new
            propositions and products and decide what to focus on next.
          </HomeBlock> */}
        </div>
        <PageHeader heading="Case Studies" headingElement="h2" />
        <Portfolio works={works} cols={2} />
      </div>
      <LogoSoup
        heading={homePageData?.logo_soup_header || ""}
        subHeading={homePageData?.logo_soup_subheader || ""}
        logos={data.homePage?.data.logo_soup?.map((l) => l.logo) as ILogo[]}
      />
      <div className="fixed-width">
        <PageHeader
          heading={homePageData?.what_makes_a_business_header || ""}
          headingElement="h2"
          fullWidth={true}
        />
        <Portfolio
          className="principles"
          works={data.homePage!.data.what_makes_the_business!.map((w) => ({
            title: w.title || "",
            description: w.body,
            imageSrc: w.image?.url,
          }))}
          cols={3}
        />
      </div>
    </>
  );
};

export const query = graphql`
  query IndexPage {
    caseStudies: allPrismicCaseStudy(limit: 4, sort: { fields: data___order }) {
      nodes {
        uid
        data {
          title
          subtitle
          order
          short_description
          card_image {
            gatsbyImageData
          }
        }
      }
    }
    homePage: prismicHomePage {
      data {
        header
        subheader
        vimeo_id
        offerings {
          title
          body
          link_text
          link_to
        }
        what_makes_a_business_header
        what_makes_the_business {
          image {
            url
            alt
          }
          title
          body
        }
        logo_soup_header
        logo_soup_subheader
        logo_soup {
          logo {
            alt
            url
          }
        }
        recent_work {
          case_study {
            uid
            document {
              ... on PrismicCaseStudy {
                id
                data {
                  title
                  subtitle
                  order
                  short_description
                  card_image {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
