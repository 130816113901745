import * as React from "react";

import "./placeholder.scss"

interface IProps {
  text?: string;
  height?: number;
}

const Placeholder: React.FunctionComponent<IProps> = ({ text, height }) => {
  return (
    <div className="placeholder" style={{ height: `${height || 500}px`}}>
      {text}
    </div>
  );
};

export default Placeholder;