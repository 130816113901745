import * as React from "react";
import HomeBlock from "../components/homeBlock";
import PageHeader from "../components/pageHeader";
import Portfolio from "../components/portfolio";
import SplitBlock from "../components/splitBlock";
import Shell from "../layout/shell";
import { graphql } from "gatsby";
import { BlogPageQuery } from "../generated/graphql";
import { orderBy } from "lodash";
import { RichText } from "prismic-reactjs";
import { format, parseISO } from "date-fns";

import "./blog.scss";

interface IProps {
  data: BlogPageQuery;
}

const Blog: React.FunctionComponent<IProps> = ({ data }) => {
  const orderedPosts = orderBy(
    data.allPrismicBlogPost.nodes,
    (bp) => bp.data.date
  );

  const mostRecentBlogPost = orderedPosts.pop();

  return (
    <>
      <div className="blog-view fixed-width">
        <PageHeader
          heading={data.prismicBlogPage?.data.heading || ""}
          headingElement="h1"
        />
        <SplitBlock
          subHeading=""
          heading={mostRecentBlogPost?.data.title || ""}
          action={{ text: "Read more", to: `/blog/${mostRecentBlogPost?.uid}` }}
          imageData={mostRecentBlogPost?.data.cover_image?.gatsbyImageData}
          imageAlt={mostRecentBlogPost?.data.cover_image?.alt || ""}
        >
          <RichText render={mostRecentBlogPost?.data.intro_copy?.richText} />
        </SplitBlock>

        <div style={{ height: "120px" }} />
        <Portfolio
          autoHeight
          className="principles"
          works={orderedPosts.map((post) => ({
            title: post.data.title ?? "",
            imageData: post.data.cover_image?.gatsbyImageData,
            subTitle: `${post.data.author}\r\n${
              post.data.author_title
            }\r\n@${format(parseISO(post.data.date), "HH:mm, dd.MM.yyyy")}`,
            path: `/blog/${post.uid}`,
          }))}
          cols={3}
          imageHeight={384}
          hideDivider={true}
        />
        <div className="no-mobile" style={{ height: "275px" }} />
      </div>
    </>
  );
};

export const query = graphql`
  query BlogPage {
    allPrismicBlogPost(filter: { data: { visible: { eq: true } } }) {
      nodes {
        uid
        data {
          title
          intro_copy {
            richText
            text
          }
          cover_image {
            gatsbyImageData
            alt
          }
          author
          author_title
          date
        }
      }
    }
    prismicBlogPage {
      data {
        heading
      }
    }
  }
`;

export default Blog;
