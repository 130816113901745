import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./pullOutCopyComponent.scss";

export const PullOutCopyComponent: React.FunctionComponent<{
  slice: { primary: any };
}> = ({ slice }) => {
  const image = getImage(slice.primary.image.gatsbyImageData);

  return (
    <div className="pull-out-copy">
      <GatsbyImage image={image!} alt={slice.primary.image.alt || ""} />
      <div>
        <p>{slice.primary.copy}</p>
      </div>
    </div>
  );
};
