import * as React from "react";
import PageHeader from "../components/pageHeader";

import Portfolio, { FilterType, IWork } from "../components/portfolio";
import { graphql } from "gatsby";
import { PropositionPrototypingPageQuery } from "../generated/graphql";

import "./brand-and-service.scss";

interface IProps {
  data: PropositionPrototypingPageQuery;
}

const PropositionPrototyping: React.FunctionComponent<IProps> = ({ data }) => {
  const works: IWork[] = data.prismicPropositionPrototyping!.data.work!.map(
    (work) => ({
      title: work.work_item!.document!["data"].title || "",
      subTitle: work.work_item!.document!["data"].subtitle,
      description: work.work_item!.document!["data"].short_description,
      imageData: work.work_item!.document!["data"].card_image?.gatsbyImageData,
      path: `/case-studies/${work.work_item!.document!["uid"]}`,
    })
  );

  const services: IWork[] =
    data.prismicPropositionPrototyping!.data.service_items!.map((item) => ({
      title: item.title || "",
      description: item.description,
      imageSrc: item.image?.url,
      includes: item.includes,
    }));

  return (
    <>
      <div className="fixed-width brand-and-service">
        <PageHeader heading={`Proposition prototyping`} headingElement="h1" />
        <img
          src={data.prismicPropositionPrototyping?.data.hero_image?.url}
          alt={data.prismicPropositionPrototyping?.data.hero_image?.alt}
        />
        <p className="jumbotron">
          {data.prismicPropositionPrototyping?.data.intro_copy}
        </p>
        <h2>{data.prismicPropositionPrototyping?.data.service_header}</h2>
        <Portfolio cols={4} works={services} />
        <div className="mobile-only" style={{ height: "60px" }} />
        <h2>Case Studies</h2>
        <Portfolio cols={2} works={works} />
        <div className="no-mobile" style={{ height: "275px" }} />
      </div>
    </>
  );
};

export const query = graphql`
  query PropositionPrototypingPage {
    caseStudies: allPrismicCaseStudy(
      filter: { data: { type: { eq: "prototype" } } }
      sort: { order: ASC, fields: data___order }
      limit: 4
    ) {
      nodes {
        uid
        data {
          title
          subtitle
          type
          order
          short_description
          card_image {
            gatsbyImageData
          }
        }
      }
    }
    prismicPropositionPrototyping {
      data {
        header
        hero_image {
          gatsbyImageData
          url
          alt
        }
        intro_copy
        service_header
        service_items {
          image {
            url
            gatsbyImageData
            alt
          }
          title
          description
          includes
        }
        work {
          work_item {
            document {
              ... on PrismicCaseStudy {
                data {
                  subtitle
                  title
                  card_image {
                    gatsbyImageData
                    alt
                  }
                  short_description
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`;

export default PropositionPrototyping;
