import { Link } from "gatsby";
import * as React from "react";

import arrowRight from "../assets/images/arrow-right.svg";

import "./homeBlock.scss";

interface IProps {
  heading: string;
  subHeading?: string;
  action?: { text: string; to: string };
}

const HomeBlock: React.FunctionComponent<IProps> = ({
  heading,
  subHeading,
  children,
  action,
}) => {
  return (
    <div className="home-block" data-no-subheading={!subHeading}>
      <h2>{heading}</h2>
      {subHeading && <h3>{subHeading}</h3>}
      <p>{children}</p>
      {action && (
        <Link className="action" to={action.to}>
          {action.text}
          <img src={arrowRight} alt="Right arrow"/>
        </Link>
      )}
    </div>
  );
};

export default HomeBlock;
