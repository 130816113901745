import { graphql } from "gatsby";
import * as React from "react";
import { orderBy  } from "lodash";
import HomeBlock from "../components/homeBlock";
import LogoSoup, { ILogo } from "../components/logoSoup";
import PageHeader from "../components/pageHeader";
import Placeholder from "../components/placeholder";
import Portfolio, { FilterType, IWork } from "../components/portfolio";
import { WorkPageQuery } from "../generated/graphql";
import Shell from "../layout/shell";

import "./work.scss";

interface IProps {
  data: WorkPageQuery;
}


const Work: React.FunctionComponent<IProps> = ({ data }) => {
  const [filter, setFilter] = React.useState<FilterType>("all");
  const works: IWork[] = data.caseStudies.nodes.map(n => ({
    title: n.data?.title || 'Case Study',
    subTitle: n.data?.subtitle,
    description: n.data?.short_description,
    imageData: n.data?.card_image?.gatsbyImageData,
    type: n.data?.type as FilterType,
    path: `/case-studies/${n.uid}`,
    order: n.data.order || 0
  }));

  let filtered = filter === "all" ? works : works.filter(w => w.type === filter);
  filtered = orderBy(filtered, f => f.order);
  return (
    <>
      <div className="fixed-width work">
        <PageHeader heading="Our work" headingElement="h1" />
        <div className="filters">
          <div data-selected={filter === "all"} onClick={() => setFilter("all")}><span>All</span></div>
          <div data-selected={filter === "end-to-end"} onClick={() => setFilter("end-to-end")}><span>End-to-end</span></div>
          <div data-selected={filter === "prototype"} onClick={() => setFilter("prototype")}><span>Prototype</span></div>
        </div>
        <Portfolio works={filtered} cols={2} />
      </div>
    </>
  );
};

export const query = graphql`
  query WorkPage {
    caseStudies: allPrismicCaseStudy {
    nodes {
      uid
      data {
        title
        subtitle
        type
        order
        short_description
        card_image{
          gatsbyImageData(imgixParams: { auto: "" })
        }
      }
    }
  }
  }
`;


export default Work;
