import { Link } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import Placeholder from "./placeholder";

import "./splitBlock.scss";

interface IProps {
  heading: string;
  subHeading: string;
  action?: { text: string; to: string };
  imageData?: IGatsbyImageData;
  imageAlt?: string;
}

const SplitBlock: React.FunctionComponent<IProps> = ({
  heading,
  subHeading,
  children,
  action,
  imageData,
  imageAlt,
}) => {
  return (
    <div className="split-block">
      {imageData ? (
        <GatsbyImage image={getImage(imageData)!} alt={imageAlt || ""} />
      ) : (
        <Placeholder />
      )}
      <div>
        {subHeading && <h3>{subHeading}</h3>}
        {heading && !subHeading && <br />}
        <h2>{heading}</h2>
        {children}
        {action && (
          <Link className="btn" to={action.to}>
            {action.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default SplitBlock;
