import * as React from "react";
import { CarouselComponent } from "./sliceComponents/carouselComponent";
import { LottieAnimationComponent } from "./sliceComponents/lottieAnimationComponent";
import { StaticImageComponent } from "./sliceComponents/staticImageComponent";
import { VimeoComponent } from "./sliceComponents/vimeoComponent";

import "./sliceHandler.scss";

interface IProps {
  slices?: { id: string; slice_type: string, primary: any }[];
  lottieData: ILottieData[];
}



export const SliceHandler: React.FC<IProps> = ({ slices, lottieData }) => {
  const sliceComponents = {
    lottie_animation__json_: LottieAnimationComponent,
    vimeo_video: VimeoComponent,
    single_image: StaticImageComponent,
    image_carousel: CarouselComponent
  }

  if (!slices){
    return null;
  }

  return <>
    {slices.map((slice, index) => {
      const SliceComponent = sliceComponents[slice.slice_type]
      if (SliceComponent) {
        return <SliceComponent slice={slice} key={`slice-${slice.id}`} lottieData={lottieData} />
      }
      console.log(`No component defined for ${slice.slice_type}`)
    })}
  </>


}
