import * as React from "react";
import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie";

export const LottieAnimationComponent: React.FunctionComponent<{
  slice: { id: string; primary: any };
  lottieData: ILottieData[];
}> = ({ slice, lottieData }) => {
  const { ref, inView, entry } = useInView({});
  const [playing, setPlaying] = React.useState(false);

  React.useEffect(() => {
    if (!ref) {
      return;
    }
    if (!inView && playing) {
      setPlaying(false);
    }
    if (inView && !playing) {
      setPlaying(true);
    }
  }, [inView]);

  return (
    <>
      <div id="bm" ref={ref} className="slice lottie-slice">
        <Lottie
          options={{
            loop: true,
            path: lottieData.find((ld) => ld.id === slice.id)?.path,
            renderer: "svg",
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          isPaused={!playing}
        />
      </div>

      {slice.primary?.caption && <p>{slice.primary.caption}</p>}
    </>
  );
};
