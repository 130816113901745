import { Link } from "gatsby";
import * as React from "react";

import arrowRight from "../assets/images/arrow-right.svg";

import "./pageHeader.scss";

interface IProps {
  heading: string;
  subHeading?: string;
  action?: { text: string; to: string };
  headingElement: "h1" | "h2";
  fullWidth?: boolean;
}

const PageHeader: React.FunctionComponent<IProps> = ({
  heading,
  subHeading,
  headingElement,
  action,
  fullWidth,
  children
}) => {
  return (
    <div className="page-header" data-fullwidth={fullWidth}>
      <div>
        {subHeading && <div className="sub-heading">{subHeading}</div>}
        {React.createElement(headingElement, { children: heading })}
        {children}
      </div>
      {action && (
        <Link className="action" to={action.to}>
          <img src={arrowRight} alt="Right arrow" />
          {action.text}
        </Link>
      )}
    </div>
  );
};

export default PageHeader;
