import * as React from "react";
import { RichText } from "prismic-reactjs";

import "./bodyCopyComponent.scss";

export const BodyCopyComponent: React.FunctionComponent<{
  slice: { primary: any };
  shareData: { title: string; url: string };
}> = ({ slice, shareData }) => {
  const share = async () => {
    try {
      await navigator.share({
        title: shareData.title,
        url: shareData.url,
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="body-copy">
      <div className="breadcrumb">
        <span>HOME / BLOG /</span>{" "}
        <span className="breadcrumb-active">{shareData.title}</span>
      </div>
      <div>
        <h3>{slice.primary.header}</h3>
        <RichText render={slice.primary.body.richText} />
        <button onClick={share}>SHARE</button>
      </div>
    </div>
  );
};
