import * as React from "react";
import { StaticImageComponent } from "./sliceComponents/staticImageComponent";
import { BodyCopyComponent } from "./blogSlideComponents/bodyCopyComponent";

import "./sliceHandler.scss";
import { PullOutCopyComponent } from "./blogSlideComponents/pullOutCopyComponent";
import { VimeoComponent } from "./sliceComponents/vimeoComponent";

interface IProps {
  slices?: { id: string; slice_type: string; primary: any }[];
  shareData: {
    title: string;
    url: string;
  };
}

export const BlogSliceHandler: React.FC<IProps> = ({ slices, shareData }) => {
  const sliceComponents = {
    pull_out_copy: PullOutCopyComponent,
    body_copy: BodyCopyComponent,
    single_image: StaticImageComponent,
    vimeo_video: VimeoComponent,
  };

  if (!slices) {
    return null;
  }

  return (
    <>
      {slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          return (
            <SliceComponent
              slice={slice}
              key={`slice-${slice.id}`}
              shareData={shareData}
            />
          );
        }
        console.log(`No component defined for ${slice.slice_type}`);
      })}
    </>
  );
};
