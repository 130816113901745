import * as React from "react";
import Placeholder from "./placeholder";

import "./logoSoup.scss";

export interface ILogo {
  url?: string;
  alt?: string;
}

interface IProps {
  heading: string;
  subHeading: string;
  logos?: ILogo[];
}

const LogoSoup: React.FunctionComponent<IProps> = ({
  heading,
  subHeading,
  logos,
}) => {
  return (
    <div className="logo-soup">
      <div className="fixed-width">
        <h2>{heading}</h2>
        <h3>{subHeading}</h3>
        <div className="soup">
          {logos && logos.map((l,i) => (
             <img key={`logo_${i}`} src={l.url} alt={l.alt} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoSoup;
