// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brand-and-service-tsx": () => import("./../../../src/pages/brand-and-service.tsx" /* webpackChunkName: "component---src-pages-brand-and-service-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-proposition-prototyping-tsx": () => import("./../../../src/pages/proposition-prototyping.tsx" /* webpackChunkName: "component---src-pages-proposition-prototyping-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

