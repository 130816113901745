import * as React from "react";
import PageHeader from "../components/pageHeader";
import Portfolio, { FilterType, IWork } from "../components/portfolio";
import { graphql } from "gatsby";
import { BrandAndServicePageQuery } from "../generated/graphql";

import BrandServiceImage1 from "../assets/images/brand-service-1.jpg";
import BrandServiceImage2 from "../assets/images/brand-service-2.jpg";
import BrandServiceImage3 from "../assets/images/brand-service-3.jpg";
import BrandServiceImage4 from "../assets/images/brand-service-4.jpg";
import brandDiagram from "../assets/images/brand-diagram.svg";

import "./brand-and-service.scss";
import { VimeoComponent } from "../components/sliceComponents/vimeoComponent";

interface IProps {
  data: BrandAndServicePageQuery;
}

const BrandAndService: React.FunctionComponent<IProps> = ({ data }) => {
  const works: IWork[] = data.prismicBrandAndService!.data.work!.map(
    (work) => ({
      title: work.work_item!.document!["data"].title || "",
      subTitle: work.work_item!.document!["data"].subtitle,
      description: work.work_item!.document!["data"].short_description,
      imageData: work.work_item!.document!["data"].card_image?.gatsbyImageData,
      path: `/case-studies/${work.work_item!.document!["uid"]}`,
    })
  );

  const services: IWork[] =
    data.prismicBrandAndService!.data.service_items!.map((item) => ({
      title: item.title || "",
      description: item.description,
      imageSrc: item.image?.url,
      includes: item.includes,
    }));

  return (
    <>
      <div className="fixed-width brand-and-service">
        <PageHeader
          heading={data.prismicBrandAndService?.data.header || ""}
          headingElement="h1"
        />
        <VimeoComponent
          vimeoId={data.prismicBrandAndService?.data.hero_vimeo_id}
        />
        <p className="jumbotron">
          {data.prismicBrandAndService?.data.intro_copy}
        </p>
        <h2>{data.prismicBrandAndService?.data.service_header}</h2>
        <Portfolio cols={4} works={services} />
        <div className="mobile-only" style={{ height: "60px" }} />
        <h2>Case Studies</h2>
        <Portfolio cols={2} works={works} />
      </div>
    </>
  );
};

export const query = graphql`
  query BrandAndServicePage {
    caseStudies: allPrismicCaseStudy(
      filter: { data: { type: { eq: "end-to-end" } } }
      sort: { order: ASC, fields: data___order }
      limit: 4
    ) {
      nodes {
        uid
        data {
          title
          subtitle
          type
          order
          short_description
          card_image {
            gatsbyImageData
          }
        }
      }
    }
    prismicBrandAndService {
      data {
        header
        hero_vimeo_id
        intro_copy
        service_header
        service_items {
          image {
            url
            gatsbyImageData
            alt
          }
          title
          description
          includes
        }
        work {
          work_item {
            document {
              ... on PrismicCaseStudy {
                data {
                  subtitle
                  title
                  card_image {
                    gatsbyImageData
                    alt
                  }
                  short_description
                }
                uid
              }
            }
          }
        }
      }
    }
  }
`;

export default BrandAndService;
