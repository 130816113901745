import { Link } from "gatsby";
import * as React from "react";
import Placeholder from "./placeholder";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";

import "./portfolio.scss";

export type FilterType = "all" | "prototype" | "end-to-end";

export interface IWork {
  title: string;
  path?: string;
  imageData?: IGatsbyImageData;
  imageSrc?: string;
  subTitle?: string;
  description?: string;
  includes?: string;
  type?: FilterType;
  order?: number;
}

interface IProps {
  works: IWork[];
  cols: 4 | 2 | 3;
  className?: string;
  imageHeight?: number;
  includesTitle?: string;
  hideImages?: boolean;
  hideDivider?: boolean;
  autoHeight?: boolean;
}

const Portfolio: React.FunctionComponent<IProps> = ({
  works,
  cols,
  className,
  includesTitle,
  imageHeight,
  hideImages,
  hideDivider,
  autoHeight,
}) => {
  let placeholderHeight = cols === 4 || cols === 3 ? 230 : 600;
  if (imageHeight) {
    placeholderHeight = imageHeight;
  }

  const style: React.CSSProperties = {};
  if (autoHeight) {
    style.height = "unset";
    style.aspectRatio = "1/1";
  }
  return (
    <div
      className={`portfolio${className ? ` ${className}` : ""}`}
      style={{ gridTemplateColumns: `repeat(${cols}, 1fr)` }}
      data-cols={cols}
      data-hide-images={hideImages}
    >
      {works.map((w) => {
        const inner = (
          <>
            {!hideImages && w.imageData && (
              <GatsbyImage
                style={style}
                image={getImage(w.imageData)!}
                alt={w.title}
              />
            )}
            {!hideImages && w.imageSrc && (
              <img alt={w.title} src={w.imageSrc} />
            )}
            {!hideImages && !w.imageData && !w.imageSrc && (
              <Placeholder height={placeholderHeight} />
            )}
            {!hideDivider && <hr />}
            <h3>{w.title}</h3>
            {w.subTitle && <div className="subtitle">{w.subTitle}</div>}
            {w.description && <p className="description">{w.description}</p>}
            {w.includes && (
              <>
                <strong>{includesTitle || "Includes"}</strong>
                <p>{w.includes}</p>
              </>
            )}
          </>
        );
        if (w.path) {
          return (
            <Link key={w.path} to={w.path}>
              {inner}
            </Link>
          );
        } else {
          return <div key={w.path}>{inner}</div>;
        }
      })}
    </div>
  );
};

export default Portfolio;
