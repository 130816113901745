import * as React from "react";
import { GatsbyImage, getImage }  from "gatsby-plugin-image"

export const StaticImageComponent: React.FunctionComponent<{ slice: { primary: any } }> = ({ slice }) => {

  const options = {
    src: `${slice.primary.image.url}`,
    alt: slice.primary.image.alt
  };

  const image = getImage(slice.primary.image.gatsbyImageData)

  return <>
  <GatsbyImage className="slice image-slice" image={image!} alt={options.alt}/>  
  {slice.primary?.caption && <p>{slice.primary.caption}</p>}
  </>
}
