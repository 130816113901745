import * as React from "react";
import PageHeader from "../components/pageHeader";
import Portfolio, { IWork } from "../components/portfolio";
import { VimeoComponent } from "../components/sliceComponents/vimeoComponent";
import { graphql } from "gatsby";
import { AboutUsPageQuery } from "../generated/graphql";

interface IProps {
  data: AboutUsPageQuery;
}

const Services: React.FunctionComponent<IProps> = ({ data }) => {
  const headlines = data.prismicAboutUs!.data.headlines!.map((headline) => ({
    title: headline.title || "",
    description: headline.description || "",
  }));
  return (
    <>
      <div className="fixed-width">
        <PageHeader
          heading={data.prismicAboutUs?.data.heading || ""}
          headingElement="h1"
        />
        <VimeoComponent vimeoId={data.prismicAboutUs?.data.hero_vimeo_id} />
        <p className="jumbotron">{data.prismicAboutUs?.data.intro_copy}</p>
        <Portfolio cols={2} works={headlines} hideImages={true} />
        <div className="no-mobile" style={{ height: "275px" }} />
      </div>
    </>
  );
};

export const query = graphql`
  query AboutUsPage {
    prismicAboutUs {
      data {
        heading
        hero_vimeo_id
        intro_copy
        headlines {
          title
          description
        }
      }
    }
  }
`;

export default Services;
